import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import ExploreMenu from '@components/fixed/exploreMenu';
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line
import cx from 'classnames';
import { SanityImage } from '@components/sanity/sanityImage';
import StandardButton from '@components/UI/standardButton';

// Components
import { PageMeta } from '@components/pageMeta';
import ModuleZone from '~moduleZone';
import Layout from '@components/layout';
import { AnchorNav } from '@components/nav/anchorNav';

const ServicePageTemplate = ({ data: { page } }) => {
  const { modules, banner, seo, anchorLinks, title } = page || {};

  const navRef = React.useRef(null);
  const bannerRef = React.useRef(null);

  const [navActive, setNavActive] = React.useState(false);

  //detect when navRef is at the top of the page
  React.useEffect(() => {
    const handleScroll = () => {
      if (navRef?.current?.getBoundingClientRect()?.top <= 0) {
        setNavActive(true);
      } else {
        setNavActive(false);
      }
    };
    const handleScroll2 = () => {
      if (bannerRef.current.getBoundingClientRect().bottom >= 0) {
        setNavActive(false);
      } else {
        setNavActive(true);
      }
    };
    anchorLinks &&
      window.addEventListener('scroll', handleScroll, { passive: true });
    anchorLinks &&
      window.addEventListener('scroll', handleScroll2, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', handleScroll2);
    };
  }, [navRef]);

  //get height of navRef
  const [navHeight, setNavHeight] = React.useState(0);
  React.useEffect(() => {
    setNavHeight(
      navRef.current ? navRef.current.getBoundingClientRect().height : 0,
    );
  }, [navRef]);

  return (
    <Layout unPinHeader={true}>
      <PageMeta {...seo} />
      <div
        ref={bannerRef}
        style={{ marginBottom: `${navHeight}px` }}
        className='relative z-20'>
        <div
          className='h-[50vh] md:h-screen w-full relative flex items-center justify-start text-white '
          id='top'>
          <SanityImage
            image={banner?.bgImage}
            className='absolute z-0 w-full h-full top-0 left-0 object-cover'
          />
          <div
            className={cx(
              'absolute top-0 left-0 w-full h-full bg-black bg-opacity-60 lg:bg-opacity-40',
              {
                'lg:bg-gradient-to-r from-black  to-transparent':
                  banner?.linearGradient,
              },
            )}
          />
          <div className='hidden h-[75vh] mt-auto md:flex relative z-10  px-gutter'>
            <div className='  self-center  relative h-auto  '>
              <h1 className='blockH3 '>{banner?.title}</h1>
              <div className='sm:max-w-[80vw] lg:max-w-[50vw]'>
                <h2 className=' blockH6 pt-8 pb-6  '>{banner?.subtitle}</h2>
              </div>
              <StandardButton buttonName='Read more' />
            </div>
          </div>
        </div>
        <div className='md:hidden relative z-10 mt-12 pb-8 lg:pb-16 px-gutter w-full text-green'>
          <h1 className='blockH3 '>{banner?.title}</h1>
          <h2 className=' blockH5 pt-8 pb-6  '>{banner?.subtitle}</h2>
        </div>
        {/* Sticky nav */}
        {anchorLinks.length !== 0 && (
          <AnchorNav
            navRef={navRef}
            anchorLinks={anchorLinks}
            navActive={navActive}
            title={title}
          />
        )}
      </div>

      <div className=' '>{modules && <ModuleZone {...modules} />}</div>
      <ExploreMenu />
    </Layout>
  );
};

export default ServicePageTemplate;

export const pageQuery = graphql`
  query standardServiceQuery($slug: String!) {
    page: sanityService(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title

      anchorLinks {
        ...AnchorLink
      }
      banner {
        title
        subtitle
        bgImage {
          ...Image
        }
        linearGradient
      }
      modules {
        ...ServiceModules
      }
    }
  }
`;
