import React from 'react';
import cx from 'classnames';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { motion } from 'framer-motion';

import AnchorScrollLink from '../links/anchorScrollLink';
import { useSiteContext } from '@lib/context';

import GreenVector from '@svg/greenvector.svg';

export const AnchorNav = ({
  navRef,
  navActive,
  anchorLinks,
  navLabel,
  title,
}) => {
  const breakpoints = useBreakpoint();

  function scrollToValue(value) {
    const element = document.getElementById(value);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  const { state } = useSiteContext();

  return (
    <div
      ref={navRef}
      id='sticky-nav'
      className={cx(
        ' py-4 bg-white lg:py-6 px-gutter w-full flex justify-between items-center text-blue z-50',
        {
          'absolute top-full': !navActive,
          'fixed top-0': navActive,
        },
      )}>
      <h1 className='self-center blockH5 text-blue'>{title}</h1>
      <span className='blockH6'>{navLabel}</span>
      {breakpoints.lg ? (
        <div className='flex gap-x-16  items-center'>
          {anchorLinks?.map((link, i) => {
            return (
              <div className='text-green'>
                <AnchorScrollLink
                  key={i}
                  {...link}
                  className={`blockH9 font block cursor-pointer  ${
                    state === link?.target?.current ? 'mb-1' : 'mb-[6px] '
                  } `}
                />
                {state === link?.target?.current && (
                  <motion.div
                    layoutId='underline'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                    className={`h-[2px] w-full  rounded-full `}
                    style={{ backgroundColor: 'var(--green)' }}
                  />
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className='relative flex  items-center'>
          <GreenVector className=' w-4 h-4 absolute top-1/2 -translate-y-1/2 right-0 rotate-90' />
          <select
            onChange={(e) => scrollToValue(e.target.value)}
            style={{ background: 'none' }}
            className='bg-opacity-0 border-0 focus:border-0 focus:ring-0 text-right'>
            <option disabled selected>
              Select
            </option>
            {anchorLinks?.map(({ linkText, target }, i) => (
              <option key={i} value={target?.current}>
                {linkText}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};
